import 'vue3-toastify/dist/index.css';
import { toast } from 'vue3-toastify';
import Client from '@/utils/client';
import { recordActivity } from '@/utils/activity';
import ToastMsg from '@/components/feedback/ToastMsg.vue';
import { h } from 'vue';

function notifyError(text, position=toast.POSITION.BOTTOM_RIGHT) {
    const props = {
        title: "Error!",
        message: text,
    };

    toast.error(h(ToastMsg, props), { position }); 
}

function notifySuccess(text, position=toast.POSITION.BOTTOM_RIGHT) {
    const props = {
        title: "Success!",
        message: text,
    };

    toast.success(h(ToastMsg, props), { position }); 
}

async function copyAction(element) {
    const copy = element.dataset.copy;
    await navigator.clipboard.writeText(copy);
}

function toggleElement(selector) {
    const element = document.querySelector(selector);
    element.open = !element.open;
}

document.querySelectorAll('[data-action]').forEach((element) => {
    const action = element.dataset.action;

    switch (action) {
        case 'copy':
            element.onclick = async () => {
                await copyAction(element);
                notifySuccess(`"${copy}" Copied to clipboard`)
            };
            break;

        case 'copy-email':
            element.onclick = async () => {
                await copyAction(element)
                notifySuccess("Email copied to clipboard");
                recordActivity("Copy email");
            };
            break;
    

        case 'share-link':
            element.onclick = async () => {
                const data = { id: element.dataset.id };
                const response = await Client.post("share_link", data);

                if (response.status != 200) {
                    return notifyError("Unable to copy profile link");
                }
                
                const json = await response.json();

                const url = location.origin + location.pathname + `?code=${json.code}`;
                await navigator.clipboard.writeText(url);

                notifySuccess("Profile link copied!");
                recordActivity("Click on share");
            };

            break;

        case 'record-activity':
            element.onclick = async () => {
                recordActivity(element.dataset.activity);
            };

            break;

        case 'toggle-dialog':
            element.addEventListener('click', async (e) => {
                e.stopPropagation();
                toggleElement(element.dataset.target);
                document.querySelector("body").style.overflow = dialog.open ? "hidden": null;
            });

            break;

        case 'toggle-details':
            element.addEventListener('click', async (e) => {
                e.preventDefault();
                toggleElement(element.dataset.target);
            });

            break;
    } 
});